.hero-home {
  width: 100%;
  height: 720px;
  overflow: hidden;
  background-blend-mode: multiply, normal;
  
  @media (max-width: 768px) {
    height: 594px;
  }

  .background-image {
    position: relative;
    height: 100%;

    .hero-overlay {
      &::before {
        background: linear-gradient(0deg, rgba(33, 46, 94, 0.75), rgba(33, 46, 94, 0.75));
        background-blend-mode: multiply;
        mix-blend-mode: multiply;
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
      }

      .container-page {
        padding-top: 124px;
        width: 100%;
        height: 720px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;

        @media (max-width: 768px) {
          height: initial;
        }

        .hero-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          padding-top: 150px;

          @media (max-width: 768px) {
            padding-top: 20px;
            margin-bottom: 38px;
          }

          .main-text {
            width: 740px;
            justify-content: center;
            align-content: center;
            font-family: 'Archivo';
            font-style: normal;
            font-weight: 700;
            font-size: 52px;
            line-height: 63px;
            text-align: center;
            color: #ffffff;
            margin-bottom: 20px;

            h1 {
              text-transform: capitalize;
            }

            @media (max-width: 768px) {
              width: 100%;
              font-size: 40px;
              line-height: 48px;
              text-align: center;
            }
          }

          .secondary-text {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            color: #ffffff;

            @media (max-width: 768px) {
              text-align: center;
              width: 100%;
              align-self: start;
            }
          }
        }

        .btn-hero-home {
          background: linear-gradient(270deg, #E75E24 0%, #F9893D 100%);
          border-radius: 30px;
          width: 142px;
          height: 56px;
          justify-content: center;
          align-items: center;
          display: flex;
          font-family: 'Archivo';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 16px;
          color: #fff;

          @media (min-width: 768px) {
            display: none;
          }

          &:hover {
            background: linear-gradient(270deg, #F9893D 0%, #F9893D 100%);
          }
        }
      }
    }
    img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      object-fit: cover;
    }
  }
}
